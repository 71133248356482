import React, {useEffect, useState} from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa';
import classNames from 'classnames';
import {classes} from './DeliveryMethod.st.css';
import s from './DeliveryMethod.scss';
import {useControllerProps} from '../../../../../../../domain/controllers/ControllerContext';
import {useTranslation, TFunction} from '@wix/yoshi-flow-editor';
import {ShippingRuleOptionModel} from '../../../../../../../domain/services/OrderService';
import {PickupMethod} from '@wix/wixstores-client-storefront-sdk';
import {SEPERATOR} from '../../../../../../../common/constants';

const isStorePickup = (option: ShippingRuleOptionModel): boolean => {
  const pickupMethod = option?.logistics?.pickupDetails?.pickupMethod;
  return option.isPickup === true && (!pickupMethod || pickupMethod === PickupMethod.STORE_PICKUP);
};

const getTitle = (t: TFunction, option: ShippingRuleOptionModel): JSX.Element | string => {
  if (!isStorePickup(option)) {
    return `${option.title} - ${option.cost?.price?.formattedConvertedAmount}`;
  }

  let description: string;
  const address = option?.logistics?.pickupDetails?.address;

  if (address.countryFullname) {
    const dataWithoutSubdivision = {
      addressLine: address.addressLine,
      city: address.city,
      country: address.countryFullname,
    };
    description = t('cart.delivery_method_picker.pickup_address_without_subdivision', dataWithoutSubdivision);

    if (address.subdivisionFullname) {
      const data = {
        ...dataWithoutSubdivision,
        subdivision: address.subdivisionFullname,
      };
      description = t('cart.delivery_method_picker.pickup_address', data);
    }

    const seperatedDescription = description ? SEPERATOR.concat(description) : /* istanbul ignore next */ '';
    return `${option.title}${seperatedDescription}`;
  }

  return `${option.title}`;
};

const getOptions = (t: TFunction, shippingRuleOptions: ShippingRuleOptionModel[]) => {
  return shippingRuleOptions.map((option) => {
    const shouldShowSubtitle = !isStorePickup(option) && option.logistics.deliveryTime;
    const dropdownOption: DropdownOptionProps = {
      id: option.id,
      value: getTitle(t, option) as any,
      isSelectable: true,
    };
    if (shouldShowSubtitle) {
      dropdownOption.subtitle = option.logistics.deliveryTime;
    }
    return dropdownOption;
  });
};

export const DeliveryMethod = () => {
  const {t} = useTranslation();
  const [dropDownKey, setDropDownKey] = useState(0);
  const {cartModel} = useControllerProps().cartStore;
  const {selectShippingOption, shippingRuleOptions} = useControllerProps().orderStore;

  useEffect(() => {
    setDropDownKey(dropDownKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingRuleOptions]);

  const getSelectedItemTooltip = () => {
    const selectedOption = shippingRuleOptions.find((option) => option.id === cartModel.selectedShippingOptionId);
    return getTitle(t, selectedOption);
  };

  return (
    <div className={s.root} data-hook={OrderSummaryDataHook.DeliveryMethodRoot} title={getSelectedItemTooltip() as any}>
      <dl>
        <dt className={s.dropDownContainer}>
          <Dropdown
            data-hook={OrderSummaryDataHook.DeliveryMethodDropdown}
            options={getOptions(t, shippingRuleOptions)}
            initialSelectedId={cartModel.selectedShippingOptionId}
            upgrade={true}
            onChange={(selectedOption: DropdownOptionProps) => selectShippingOption(selectedOption.id)}
            className={classNames(classes.dropdown, s.dropDown)}
            placement={'bottom'}
            key={dropDownKey}
          />
        </dt>
      </dl>
    </div>
  );
};
