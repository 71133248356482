import React from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {useControllerProps} from '../../../../../../../domain/controllers/ControllerContext';
import {ShippingDestination} from '../ShippingDestination/ShippingDestination';
import {useTranslation} from '@wix/yoshi-flow-editor';
import type {TFunction} from '@wix/wix-i18n-config';
import orderSummaryStyle from '../../OrderSummary.scss';

const ShippingDestinationAndShippingRates = () => {
  const {t} = useTranslation();
  const {shouldDisplayDeliveryError, isPickup, isPickupPoint} = useControllerProps().orderStore;
  const {estimatedTotals} = useControllerProps().cartStore;

  const displayPrice = !shouldDisplayDeliveryError;
  return (
    <dl className={orderSummaryStyle.line}>
      <dt>
        <div data-hook={OrderSummaryDataHook.TotalShippingShippingDestinationWithTitle}>
          <span data-hook={OrderSummaryDataHook.TotalShippingTitle}>
            {getTotalShippingTitle({t, isPickup, isPickupPoint})}
          </span>
          &nbsp;
          <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationRoot} />
        </div>
      </dt>

      {displayPrice && (
        <dd
          data-hook={OrderSummaryDataHook.TotalShippingEstimatedShipping}
          data-wix-total-shipping-estimated-shipping={OrderSummaryDataHook.TotalShippingEstimatedShipping}>
          {estimatedTotals.priceSummary.shipping.convertedAmount > 0
            ? estimatedTotals.priceSummary.shipping.formattedConvertedAmount
            : t('cart.free_shipping')}
        </dd>
      )}
    </dl>
  );
};

export const TotalShipping = () => {
  const {isEstimatedDestinationIsShippable} = useControllerProps().orderStore;

  return (
    <div data-hook={OrderSummaryDataHook.TotalShippingRoot}>
      {isEstimatedDestinationIsShippable ? (
        <ShippingDestinationAndShippingRates />
      ) : (
        <dt>
          <ShippingDestination dataHook={OrderSummaryDataHook.ShippingDestinationRoot} />
        </dt>
      )}
    </div>
  );
};

function getTotalShippingTitle({
  t,
  isPickup,
  isPickupPoint,
}: {
  t: TFunction;
  isPickup: boolean;
  isPickupPoint: boolean;
}) {
  if (isPickupPoint) {
    return t('cart.estimated_shipping');
  }
  return isPickup ? t('cart.estimated_pickup') : t('cart.estimated_shipping');
}
